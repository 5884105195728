import React from 'react';
import '../../assets/css/main-menu.css';
import LeftMenu from './LeftMenu';
import RightMenu from './RightMenu';

function MainMenu() {
  return (
    <div className='main-menu'>
        <div className='main-menu__left'>
            <LeftMenu />
        </div>
        <div className='main-menu__right'>
           <RightMenu />
        </div>
    </div>
  );
}

export default MainMenu;
