import * as React from 'react';

import CardItem from '../item/CardItem';

const items = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
];

export default function ViewCardItem() {

  return (
    <div className='viewcard-container'>
      {items.map((item, index) => (<CardItem index={index}/>))}
    </div>
  );
}
