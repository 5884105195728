import {List, ListItemText, Typography} from '@mui/material';
import * as React from 'react';

export default function QuickLink() {
  return (
    <div>
      <Typography
        sx={{
        color: 'text.primary',
        fontSize: 20,
        fontWeight: 600,
        fontFamily: "'DM Serif Display',serif"
      }}>Links</Typography>

      <List dense={false}>
        <ListItemText primary="Cửa hàng"/>
        <ListItemText primary="Cây cảnh"/>
        <ListItemText primary="Gấu bông"/>
        <ListItemText primary="Giỏ hàng"/>
        <ListItemText primary="Tài khoản"/>
      </List>
    </div>
  );
}