import * as React from 'react';
import flower from '../../assets/images/flower-001.jpg';
import {Rating, Card, CardContent, Typography, CardActionArea} from '@mui/material';
import {useNavigate} from 'react-router-dom';

export default function CardItem(props) {
  const navigate = useNavigate();

  function handleClick() {
    navigate("/products");
  }

  return (
    <Card className='viewcard-item' key={props.index} onClick={handleClick}>
      <CardActionArea>
        <div className='viewcard-item__sale'>-30%</div>
        <img
          src={flower}
          alt="flower"
          style={{
          width: '100%',
          height: 'auto'
        }}/>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Plants
          </Typography>
          <Rating value={4.5} precision={0.5} name="simple-controlled" readOnly={true}/>
          <Typography
            variant="body2"
            sx={{
            color: 'text.secondary'
          }}>
            250.000 VND
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}