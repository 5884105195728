import { Grid2 } from "@mui/material";
import * as React from "react";

export default function ProductSadPlantSigns() {
  return (
    <Grid2 container size={12}>
      <Grid2 item size={6}></Grid2>
      <Grid2 item size={6}>
        <div className="product-detail__sad-plant-signs">
          <h3 className="product-detail__sad-plant-signs__header">
            Sad plant signs
          </h3>
          <div className="product-detail__sad-plant-signs__desc">
            Mauris justo velit, faucibus egestas bibendum non, aliquam eget
            odio. Curabitur faucibus ex non feugiat sagittis. Integer.
          </div>

          <div className="product-detail__sad-plant-signs__content">
            <div className="product-detail__sad-plant-signs__content__list">
              <i className="product-detail__sad-plant-signs__content__icon fa-solid fa-arrow-right-long"></i>
              <div>
                <div className="product-detail__sad-plant-signs__content__item-prop">
                  Leaves turning brown and crispy at the edges
                </div>
                <div className="product-detail__sad-plant-signs__content__item-desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  elit tellus, luctus nec ullamcorper mattis.
                </div>
              </div>
            </div>

            <div className="product-detail__sad-plant-signs__content__list">
              <i className="product-detail__sad-plant-signs__content__icon fa-solid fa-arrow-right-long"></i>
              <div>
                <div className="product-detail__sad-plant-signs__content__item-prop">
                  Wilting plant, dry potting mix
                </div>
                <div className="product-detail__sad-plant-signs__content__item-desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  elit tellus, luctus nec ullamcorper mattis.
                </div>
              </div>
            </div>

            <div className="product-detail__sad-plant-signs__content__list">
              <i className="product-detail__sad-plant-signs__content__icon fa-solid fa-arrow-right-long"></i>
              <div>
                <div className="product-detail__sad-plant-signs__content__item-prop">
                  Yellowing leaves or black stems
                </div>
                <div className="product-detail__sad-plant-signs__content__item-desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  elit tellus, luctus nec ullamcorper mattis.
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid2>
    </Grid2>
  );
}
