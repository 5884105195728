import { Box, Checkbox, Grid2, Rating } from "@mui/material";
import * as React from "react";
import { CustomTextField } from "../common/CustomTextField";
import { CustomButton } from "../common/CustomButton";

export default function Review() {
  return (
    <Grid2 size={12}>
      <div className="product-detail__review">
        <div className="product-detail__review-text">Đánh giá</div>
        <div className="product-detail__review-detail">
          <div className="product-detail__review-detail__header">
            Chưa có đánh giá nào
          </div>
          <div className="product-detail__review-form">
            <Box sx={{}}>
              <div>
                <div className="product-detail__review-reply-title">
                  Be the first to review "Aloe Juvenna Plant"
                </div>
                <div className="product-detail__review-reply-note">
                  Your email address will not be published. Required fields are
                  marked *
                </div>
              </div>

              <div className="product-detail__review-form-rating">
                <label htmlFor="name">Đánh giá *</label>
                <Rating value={0} precision={0.5} />
              </div>

              <div className="product-detail__review-form-review">
                <label htmlFor="name">Nhận xét *</label>
                <textarea
                  className=""
                  rows={8}
                  cols={45}
                  style={{ width: "100%" }}
                />
              </div>
              <div className="product-detail__review-user">
                <div style={{ width: "49%" }}>
                  <label>Name *</label>
                  <CustomTextField fullWidth size="small" id="name" />
                </div>
                <div style={{ width: "49%" }}>
                  <label>Email *</label>
                  <CustomTextField fullWidth size="small" id="email" />
                </div>
              </div>

              <div className="product-detail__review-checkbox">
                <Checkbox sx={{ padding: 0, margin: 0 }} />
                <div className="product-detail__review-checkbox-text">
                  Save my name, email, and website in this browser for the next
                  time I comment.
                </div>
              </div>

              <div className="product-detail__review-submit">
                <CustomButton variant="contained">Gửi</CustomButton>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </Grid2>
  );
}
