import * as React from "react";
import MainMenu from "../menu/MainMenu";
import "../../assets/css/product-detail.css";
import { Box, Button, Grid2, styled } from "@mui/material";
import Footer from "../footer/Footer";
import flower from "../../assets/images/flower-001.jpg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import ProductDescription from "./ProductDescription";
import ProductCareGuide from "./ProductCareGuide";
import ProductSadPlantSigns from "./ProductSadPlantSigns";
import Review from "./Review";
import RelatedProduct from "./RelatedProduct";

const CustomButton = styled(Button)(({ customStyle }) => ({
  ...customStyle,
}));

export default function ProductDetail() {
  const [productCount, setProductCount] = React.useState(1);

  const removeCountHandler = () => {
    if (productCount === 1) {
      return;
    }

    setProductCount(productCount - 1);
  };

  return (
    <div>
      <div style={{ padding: "0 200px", borderBottom: "1px solid #e9ecef" }}>
        <MainMenu />
      </div>
      <div className="product-detail">
        <div className="product-detail__container">
          <Box sx={{ bgcolor: "#FFF", marginTop: "4em" }}>
            <Box className="product-detail__content">
              <Grid2 container spacing={2}>
                <Grid2 item size={6}>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div
                      style={{
                        width: "100%",
                        marginBottom: 10,
                        display: "flex",
                      }}
                    >
                      <img src={flower} style={{ flex: 1 }} />
                    </div>
                    <Grid2 container spacing={2}>
                      <Grid2 item size={6}>
                        <img src={flower} style={{ maxWidth: "100%" }} />
                      </Grid2>
                      <Grid2 item size={6}>
                        <img src={flower} style={{ maxWidth: "100%" }} />
                      </Grid2>
                    </Grid2>
                  </div>
                </Grid2>
                <Grid2 item size={6} style={{ paddingLeft: 30 }}>
                  <div style={{ position: "sticky", top: "1em" }}>
                    <div
                      style={{
                        display: "flex",
                        gap: 10,
                        position: "relative",
                        float: "right",
                      }}
                    >
                      <div className="product-detail__icon">
                        <ArrowBackIosIcon
                          style={{
                            color: "#FFF",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-36%, -50%)",
                          }}
                        />
                      </div>
                      <div className="product-detail__icon">
                        <ArrowForwardIosIcon
                          style={{
                            color: "#FFF",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-36%, -50%)",
                          }}
                        />
                      </div>
                    </div>

                    <div className="product-detail__name">
                      <div
                        style={{
                          fontFamily: "'Waterfall', cursive",
                          fontSize: 60,
                          fontWeight: 500,
                          overflowWrap: "break-word",
                        }}
                      >
                        Rubber Plant
                      </div>
                    </div>

                    <div className="product-detail__price">
                      <div
                        style={{
                          color: "rgba(103,104,103,0.95)",
                          fontSize: "1.5rem",
                          fontWeight: 700,
                          fontFamily: "'DM Sans',sans-serif",
                        }}
                      >
                        $85.00
                      </div>
                      <div
                        style={{
                          color: "rgba(103,104,103,0.95)",
                          fontSize: "1rem",
                          fontWeight: 500,
                          fontFamily: "'DM Sans',sans-serif",
                        }}
                      >
                        & Free shipping
                      </div>
                    </div>

                    <div className="product-detail__intro">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nam ultricies feugiat consectetur. Ut dictum massa quis
                      felis pulvinar maximus. Duis tempor commodo tempus. Lorem
                      ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                    <div className="product-detail__button">
                      <div style={{ display: "flex" }}>
                        <CustomButton
                          customStyle={{
                            border: "1px solid #e0eae8",
                            width: 38,
                            minWidth: 35,
                            borderRight: "none",
                            padding: 0,
                            borderRadius: 0,
                            fontSize: 18,
                            color: "rgba(103,104,103,0.95)",
                          }}
                          onClick={removeCountHandler}
                        >
                          -
                        </CustomButton>

                        <input
                          type="number"
                          class="product-detail__input-text"
                          name="quantity"
                          value={productCount}
                          aria-label="Product quantity"
                          size="4"
                          min="1"
                          max=""
                          step="1"
                          placeholder=""
                          inputmode="numeric"
                          autocomplete="off"
                        />

                        <CustomButton
                          customStyle={{
                            border: "1px solid #e0eae8",
                            width: 38,
                            minWidth: 35,
                            borderLeft: "none",
                            padding: 0,
                            borderRadius: 0,
                            fontSize: 18,
                            color: "rgba(103,104,103,0.95)",
                          }}
                          onClick={() => setProductCount(productCount + 1)}
                        >
                          +
                        </CustomButton>
                      </div>
                      <CustomButton
                        customStyle={{
                          color: "#FFFFFF",
                          borderRadius: 0,
                          backgroundColor: "#586f69",
                          "&:hover": {
                            backgroundColor: "#7a9d91",
                            borderColor: "#7a9d91",
                            color: "#FFFFFF",
                          },
                        }}
                      >
                        Add to cart
                      </CustomButton>
                    </div>
                    <fieldset className="product-detail__payment">
                      <legend className="product-detail__payment-legend">
                        Thanh toán
                      </legend>
                      <i className="product-detail__payment-method fa-brands fa-cc-visa"></i>
                      <i className="product-detail__payment-method fa-brands fa-cc-mastercard"></i>
                      <i className="product-detail__payment-method fa-brands fa-cc-paypal"></i>
                      <i className="product-detail__payment-method fa-brands fa-cc-discover"></i>
                    </fieldset>

                    <div className="product-detail__attributes">
                      <ul style={{ listStyle: "none", padding: 0 }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div>
                            <CheckCircleSharpIcon
                              style={{
                                color: "rgba(103, 104, 103, 0.95)",
                                fontSize: 20,
                                marginRight: 5,
                              }}
                            />
                          </div>
                          <div className="product-detail__attributes-text">
                            No-Risk Money Back Guarantee!
                          </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div>
                            <CheckCircleSharpIcon
                              style={{
                                color: "rgba(103, 104, 103, 0.95)",
                                fontSize: 20,
                                marginRight: 5,
                              }}
                            />
                          </div>
                          <div className="product-detail__attributes-text">
                            No Hassle Refunds
                          </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div>
                            <CheckCircleSharpIcon
                              style={{
                                color: "rgba(103, 104, 103, 0.95)",
                                fontSize: 20,
                                marginRight: 5,
                              }}
                            />
                          </div>
                          <div className="product-detail__attributes-text">
                            Secure Payments
                          </div>
                        </div>
                      </ul>
                    </div>

                    <div class="product-detail__meta">
                      <span class="posted_in">Categories: Cacti, Plants</span>
                    </div>
                  </div>
                </Grid2>

                <ProductDescription />
              </Grid2>
            </Box>

            <ProductCareGuide />
            <ProductSadPlantSigns />
            <Review/>
            <RelatedProduct />
          </Box>
        </div>
      </div>

      <Footer />
    </div>
  );
}
