import { Button, styled } from "@mui/material";

export const CustomButton = styled(Button)(({}) => ({
  color: "#FFFFFF",
  borderRadius: 0,
  backgroundColor: "#586f69",
  "&:hover": {
    backgroundColor: "#7a9d91",
    borderColor: "#7a9d91",
    color: "#FFFFFF",
  },
}));
