import * as React from 'react';
import logo from '../../assets/images/logo-regular.png';

export default function ShopDescription() {
  return (
    <div>
      <img src={logo}/>
      <p style={{
        maxWidth: 300
      }}>
        Website ươm mầm ước mơ của Coca và Lani
      </p>
    </div>
  );
}
