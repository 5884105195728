import * as React from 'react';
import '../../assets/css/footer.css'
import { Grid2 } from '@mui/material';
import ShopDescription from './ShopDescription';
import QuickLink from './QuickLink';
import SocialList from './SocialList';
import Subscribe from './Subscribe';

export default function Footer() {
  return (
    <div className="footer">
      <Grid2 container spacing={15}>
          <Grid2 size={3}>
            <ShopDescription />
          </Grid2>
          <Grid2 size={3}>
            <QuickLink />
          </Grid2>
          <Grid2 size={3}>
            <SocialList />
          </Grid2>
          <Grid2 size={3}>
            <Subscribe />
          </Grid2>
      </Grid2>
    </div>
  );
}
