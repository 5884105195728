import {Typography} from '@mui/material';
import * as React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

export default function SocialList() {
  return (
    <div>
      <Typography
        sx={{
        color: 'text.primary',
        fontSize: 20,
        fontWeight: 600,
        fontFamily: "'DM Serif Display',serif"
      }}>Mạng xã hội</Typography>

      <div style={{
        display: 'flex',
        gap: 15,
      }}>
        <FacebookIcon style={{ fontSize: 30, padding: 5, backgroundColor: '#FFF', color: '#586f69' }} />
        <InstagramIcon  style={{ fontSize: 30, padding: 5, backgroundColor: '#FFF', color: '#586f69' }} />
        <TwitterIcon  style={{ fontSize: 30, padding: 5, backgroundColor: '#FFF', color: '#586f69' }} />
      </div>
    </div>
  );
}