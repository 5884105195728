import * as React from 'react';
import Box from '@mui/material/Box';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function RightMenu() {
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <div className='right-menu__item'>Về chúng tôi</div>
        <div className='right-menu__item'>Câu hỏi</div>
        <ShoppingCartIcon style={{ color: '#7a9d91', margin: '10px 15px', cursor: 'pointer'  }} />
        <AccountCircleIcon style={{ color: '#7a9d91', margin: '10px 15px', cursor: 'pointer' }} />
      </Box>
    </React.Fragment>
  );
}
