import * as React from 'react';
import CardItem from '../item/CardItem';
import { Grid2 } from '@mui/material';

export default function RelatedProduct() {
  return (
    <div className='product-detail__related-product'>
      <div className='product-detail__related-product__desc'>
        Sản phẩm liên quan
      </div>

      <Grid2 container spacing={3} className="product-detail__related-product__item">
        <Grid2 item size={4}>
          <CardItem />
        </Grid2>
        <Grid2 item size={4}>
          <CardItem />
        </Grid2>
        <Grid2 item size={4}>
          <CardItem />
        </Grid2>
      </Grid2>
    </div>
  )
}