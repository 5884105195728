import {Button, styled, TextField, Typography} from '@mui/material';
import * as React from 'react';
import { CustomButton } from '../common/CustomButton';
import { CustomTextField } from '../common/CustomTextField';

export default function Subscribe() {
  return (
    <div>
      <Typography
        sx={{
        color: 'text.primary',
        fontSize: 20,
        fontWeight: 600,
        fontFamily: "'DM Serif Display',serif",
        marginBottom: 3,
      }}>Đăng ký nhận thông tin mới</Typography>

      <CustomTextField size='normal' id="outlined-basic"  variant="outlined" placeholder='Your email address' />

      <div style={{ margin: '25px 0' }}></div>

      <CustomButton variant="contained">Subscribe</CustomButton>
    </div>
  );
}