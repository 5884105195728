import { styled, TextField } from "@mui/material";

export const CustomTextField = styled(TextField)(({ customStyle }) => ({
    ...customStyle,
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 0,
      borderColor: "var(--ast-global-color-6)",
    },
    "&:hover fieldset": {
      borderColor: "var(--ast-global-color-6)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--ast-global-color-6)",
    },
  },
}));
