import * as React from 'react';
import {
    Rating,
    Grid2,
    Typography
} from '@mui/material';
import flower from '../../assets/images/flower-001.jpg';

const items = [1,2,3,4,5,6, 7, 8];

export default function ViewListItem() {
  return (
   <div style={{ margin: '30px 25px' }}>
        <Grid2 container spacing={2}>
            { items.map((item, index) => (
              <Grid2 size={{ sm: 12, xl: 6 }} key={index} sx={{ marginBottom: 3 }}>
                   <div style={{ display: 'flex' }} >
                      <img src={flower} style={{ width: 220, height: 303 }} />
                      <div style={{ marginLeft: 15, textAlign: 'left', alignSelf: 'center' }}>
                        <Typography style={{ fontSize: '.85em', marginBottom: '.5em', display: 'block', lineHeight: '1.3', opacity: '0.6' }}>Cacti</Typography>
                        <Typography style={{ fontFamily: "'DM Serif Display', serif", padding: 0, marginBottom: '.5em', fontSize: 24, fontWeight: 500 }}>Aloe Juvenna Plant</Typography>
                        <Rating
                          value={4.5}
                          precision={0.5}
                          name="simple-controlled"
                          readOnly={true}
                        />
                        <Typography variant="body2" sx={{ color: 'text.secondary', fontWeight: 'bold' }}>
                          250.000 VND
                        </Typography>
                        <Typography style={{ color: 'rgba(103,104,103,0.95)' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam ultricies feugiat consectetur. Ut dictum massa quis felis pulvinar maximus. Duis tempor commodo tempus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>
                      </div>
                  </div>
                </Grid2>
            ))}
        </Grid2>
   </div>
  );
}
