import React from 'react';
import MainMenu from './components/menu/MainMenu';
import ProductList from './components/shop/ProductList';
import Footer from './components/footer/Footer';

function Home() {
  return (
    <div>
      <div style={{ padding: '0 200px', borderBottom: '1px solid #e9ecef' }}>
        <MainMenu/>
      </div>
      <div style={{
        margin: '0px 200px'
      }}>
        <ProductList/>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
