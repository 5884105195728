import { Grid2 } from "@mui/material";
import * as React from "react";

export default function ProductDescription() {
  return (
    <Grid2 size={12}>
      <div className="product-detail__description">
        <div className="product-detail__description-text">Description</div>
        <div className="product-detail__description-detail">
          <div className="product-detail__description-detail__header">
            About the Plant
          </div>
          <div className="product-detail__description-detail__desc">
            Proin pharetra leo arcu, eu maximus neque condimentum ac. Aliquam
            ornare dolor sit amet libero euismod, eget.
          </div>
          <div className="product-detail__description-detail__body">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
            ultricies feugiat consectetur. Ut dictum massa quis felis pulvinar
            maximus. Duis tempor commodo tempus. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Curabitur et nibh et nunc dignissim
            convallis. Vestibulum eu est sit amet justo semper aliquam. Morbi id
            vulputate justo. Vivamus tincidunt sagittis sem eget fermentum.
            Morbi ac tortor rhoncus, placerat ipsum sit amet, gravida magna.
            Curabitur maximus dolor non tellus elementum, id imperdiet turpis
            tincidunt. Vivamus sem nunc, sagittis ut eros vel, ultrices
            efficitur quam. Mauris volutpat pharetra felis eget facilisis.
          </div>
        </div>
      </div>
    </Grid2>
  );
}
