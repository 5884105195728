import React, { useState } from "react";
import TuneIcon from "@mui/icons-material/Tune";
import WindowIcon from "@mui/icons-material/Window";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewCardItem from "./ViewCardItem";
import ViewListItem from "./ViewListItem";
import "../../assets/css/shop.css";
function ProductList() {
  const [isShowViewList, setIsShowViewList] = useState(false);

  return (
    <div>
      <div
        style={{
          margin: "4em 0 20px 25px",
          marginTop: "4em",
          fontSize: 13,
          color: "#777",
        }}
      >
        Trang chủ / Cửa hàng
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 25px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TuneIcon
            style={{
              color: "#777",
            }}
          />
          <span
            style={{
              marginLeft: "5px",
            }}
          >
            Lọc
          </span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>Sắp xếp mặc định</div>

          <WindowIcon
            style={{
              color: isShowViewList ? "#777" : "#7a9d91",
              marginLeft: "10px",
            }}
            onClick={() => setIsShowViewList(false)}
          />

          <ViewListIcon
            style={{
              color: isShowViewList ? "#7a9d91" : "#777",
              fontSize: "30px",
              marginLeft: "10px",
            }}
            onClick={() => setIsShowViewList(true)}
          />
        </div>
      </div>

      {isShowViewList ? <ViewListItem /> : <ViewCardItem />}
    </div>
  );
}

export default ProductList;
