import { Grid2 } from "@mui/material";
import * as React from "react";

export default function ProductCareGuide() {
  return (
    <Grid2 container size={12}>
      <Grid2 item size={6}>
        <div className="product-detail__care-guide">
          <h3 className="product-detail__care-guide__header">Care Guide</h3>
          <div className="product-detail__care-guide__desc">
            Sed at eleifend nisi. Mauris justo velit, faucibus egestas bibendum
            non, aliquam eget odio. Curabitur faucibus ex non feugiat sagittis.
            Integer sed posuere lorem.
          </div>

          <div className="product-detail__care-guide__content">
            <div className="product-detail__care-guide__content__list">
              <i className="product-detail__care-guide__content__icon fa-solid fa-cloud-rain"></i>
              <div>
                <div className="product-detail__care-guide__content__item-prop">
                  Frequent Watering
                </div>
                <div className="product-detail__care-guide__content__item-desc">
                  Amet, consectetur adipiscing elit. Ut elit tellus, luctus nec
                  ullamcorper mattis, pulvinar dapibus leo.
                </div>
              </div>
            </div>

            <div className="product-detail__care-guide__content__list">
              <i className="product-detail__care-guide__content__icon fa-solid fa-sun"></i>
              <div>
                <div className="product-detail__care-guide__content__item-prop">
                  Most Light Conditions
                </div>
                <div className="product-detail__care-guide__content__item-desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  elit tellus, luctus nec ullamcorper mattis.
                </div>
              </div>
            </div>

            <div className="product-detail__care-guide__content__list">
              <i className="product-detail__care-guide__content__icon fa-solid fa-water"></i>
              <div>
                <div className="product-detail__care-guide__content__item-prop">
                  Humidity
                </div>
                <div className="product-detail__care-guide__content__item-desc">
                  Dolor sit amet, consectetur adipiscing elit. Ut elit tellus,
                  luctus nec ullamcorper, pulvinar.
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid2>
      <Grid2 item size={6}></Grid2>
    </Grid2>
  );
}
